import React, { Component } from 'react';
import { navigate, graphql } from 'gatsby';
import encode from '../utils/'
import Layout from '../components/layout';
import axios from 'axios';

class Contact extends Component {
  constructor(props){
    super(props);
    this.state = {
      form: {},
    };
  }
  handleChange = (e) => {
    const { form } = this.state;
    this.setState({
      form: { ...form, ...{ [e.target.name]: e.target.value } },
    });
  }

  handleSubmit = (e) => {
    const { form } = this.state;
    // fetch('/', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //   body: encode({ 'form-name': 'contact', ...form }),
    // })
      axios({
        method: 'post',
        url: '/',
        data: encode({ "form-name": "contact-us", ...form }),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then(() => navigate('/success'))
      .catch(error => window.console.log(error));

    window.console.log('Form data', form);
    e.preventDefault();
  }
  render() {
    const { dataJson: { address, phoneNumber, email, map } } = this.props.data;
    return (
        <Layout>
          <section className="breadcrumb-area">
            <div className="container text-center">
              <h1>Contact</h1>
            </div>
          </section>
          {/* End breadcrumb area */}
          {/* Start breadcrumb bottom area */}
          <section className="breadcrumb-botton-area">
            <div className="container">
              <div className="left pull-left">
                <ul>
                  <li><a href="index.html">Home</a></li>
                  <li><i className="fa fa-caret-right" aria-hidden="true" /></li>
                  <li>Contact</li>
                </ul>
              </div>
              <div className="right pull-right">
                {/* <a href="#">
                  <i className="fa fa-share-alt" aria-hidden="true" />
Share
                </a> */}
              </div>
            </div>
          </section>
          {/* End breadcrumb bottom area */}
          {/* Start contact area */}
          <section className="contact-area">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="contact-info">
                    <div className="title">
                      <h2>Contact Details</h2>
                    </div>
                    <div className="accordion-box">
                      {/* Start single accordion box */}
                      <div className="accordion accordion-block">
                        <div className="accord-btn active"><h4>Store</h4></div>
                        <div className="accord-content collapsed">
                          <ul className="contact-info-list">
                            <li>
                              <div className="icon-holder">
                                <span className="flaticon-home-page" />
                              </div>
                              <div className="text-holder">
                                <h5>
                                  <span>{address.line1}</span>
                                  <br />
                                  {address.line2}
                                </h5>
                              </div>
                            </li>
                            <li>
                              <div className="icon-holder">
                                <span className="flaticon-call-answer" />
                              </div>
                              <div className="text-holder">
                                <h5>
                                  <span>Call Us</span>
                                  <br />
                                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                                </h5>
                              </div>
                            </li>
                            <li>
                              <div className="icon-holder">
                                <span className="flaticon-envelope" />
                              </div>
                              <div className="text-holder">
                                <h5>
                                  <span>Mail Us</span>
                                  <br />
                                  <a href={`mailto:${email}`}>{email}</a>
                                </h5>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="contact-form">
                    <div className="title">
                      <h2>Contact Details</h2>
                    </div>
                    <form method="post" action="/success" className="default-form" name="contact-us" data-netlify="true" data-netlify-honeypot="bot-field">
                      <input type="hidden" name="form-name" value="contact-us" />
                      <div className="row">
                        <div className="col-md-6">
                          <input type="text" onChange={this.handleChange} name="name" placeholder="Your Name*" required />
                        </div>
                        <div className="col-md-6">
                          <input type="email" onChange={this.handleChange} name="email" placeholder="Your Mail*" required />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <input type="text" onChange={this.handleChange} name="phone" placeholder="Phone" />
                        </div>
                        <div className="col-md-6">
                          <input type="text" onChange={this.handleChange} name="subject" placeholder="Subject" required />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <textarea onChange={this.handleChange} name="message" placeholder="Your Message.." defaultValue="" required />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" defaultValue />
                          <button className="thm-btn bg-1" type="submit" data-loading-text="Please wait...">send message</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="google-map-area">
            <div className="google-map">
              <iframe title="Cell Tech For Less" src={map} aria-hidden="true" frameBorder="0" allowFullScreen width="100%" height="500px" />
            </div>
          </section>
        </Layout>
    );
  }
}
export default Contact;

export const pageQuery = graphql`
query siteData {
  dataJson{
    phoneNumber
    email
    map
    address{
      line1
      line2
    }
  }
}`;
